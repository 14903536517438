import React from "react";

import Layout from "components/layout";

const tutoringSessions = () => {
  //ToDo: add a youtube video
  return (
    <Layout title={"دروس خصوصية"}>
      <h3>أهلا و سهلا بكم أعزائي التلاميذ, أنا الأستاذ بن أحمد محمد الأمين</h3>
      <p>
        أستاذ العلوم الفيزيائية في الطور الثانوي, لدي أكثر من
        <b>10 سنوات خبرة</b>
      </p>
      <p>
        أقدم دروس خصوصية لجيمع المستويات عبر
        <b> الأنترنت </b>
        أو حضوريا في وهران
      </p>
      <p>
        سعر الدروس شهريا
        <b> 2500 دينار جزائري </b>
        للدروس الخصوصية الجماعية (أكثر من 6 تلاميذ وأقل أو يساوي 20 تلميذ لكل
        مجموعة) بمعدل حصة أسبوعيا
      </p>
      <p>
        سعر الدروس الخصوصية الفردية
        <b> 5000 دينار جزائري </b>
        للحصة الواحدة مع
        <b> حصة مجانية </b>
        كل 3 حصص متتالية (أقل من 7أيام بين كل حصة و أخرى)
      </p>
      <p>
        مكان التدريس يكون عبر
        <b> الأنترنت </b>
        أو منزل التلميذ أو بعض مدارس الدروس الخصوصية في وهران
      </p>
      <p>
        <a
          href="tel:213697707418"
          target="_blanck"
          className="button primary"
          style={{ marginTop: "1em", width: "100%" }}
        >
          لحجز مكان إتصلوا بي على الرقم 0697707418
        </a>
      </p>
      <p>تبدأ الدروس الخصوصية عندما يبلغ الفوج 6 تلاميذ على الأقل</p>
    </Layout>
  );
};

export default tutoringSessions;
